import React, { version } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { Box, Flex, Heading } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'
import { withI18next, Link } from 'gatsby-plugin-i18n'

const PresentationsCategoriesTemplate = ({ data, pageContext }) => {
  const { presentationCategories, page } = data
  const { language } = pageContext
  const { title, content } = page || {}
  const contentHTML = content ? draftjsContentToHTML(content) : null

  return (
    <Layout language={language}>
      <main>
        <LightSection>
          <Container maxWidth="60rem">
            <SectionTitle as="h1">{title || 'Categories'}</SectionTitle>

            <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
          </Container>
        </LightSection>

        <Box
          sx={{
            maxWidth:
              (page &&
                page.settings &&
                page.settings.carousel &&
                page.settings.carousel.width) ||
              'none',
            mx: 'auto',
          }}
        >
          {presentationCategories &&
            presentationCategories.edges &&
            presentationCategories.edges.map(({ node: category }) => {
              if (!category) {
                return null
              }

              const { image } = category

              return (
                <Link
                  key={category.id}
                  to={`/presentation-category/${category.slug}`}
                  style={{
                    height: '100%',
                    textDecoration: 'none',
                  }}
                >
                  <Flex
                    sx={{
                      height: '100%',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    {image && image.local && (
                      <Box
                        sx={{
                          mb: 3,
                          height: '100%',
                        }}
                      >
                        <Img
                          fluid={image.local.childImageSharp.fluid}
                          alt={version.title}
                        />
                      </Box>
                    )}

                    <Heading
                      as="h2"
                      sx={{
                        mt: image ? 'auto' : '0',
                        justifySelf: 'center',
                      }}
                    >
                      {category.title}
                    </Heading>
                  </Flex>
                </Link>
              )
            })}
        </Box>
      </main>
    </Layout>
  )
}

PresentationsCategoriesTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(PresentationsCategoriesTemplate)

export const pageQuery = graphql`
  query PresentationsCategoriesTemplate($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    page: pages(
      template: { eq: "presentationCategories" }
      language: { eq: $language }
    ) {
      title
      content
      settings {
        carousel {
          width
        }
      }
    }
    presentationCategories: allPresentationCategories(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            local {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
